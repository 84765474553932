import React from 'react';
import shortid from  "shortid";
const LimeFlightSection4 = (props) => {

    return (
        <section className="section-4">
            <div className="container">
                <div className="image-block">
                    <div className="image-items">
                        {props.imageslist?.map((object, i) => {
                            return (
                                <div className="item" key={shortid.generate()}>
                                    <img
                                        src={object.image1x?.sourceUrl}
                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                        width={object.image1x?.width}
                                        height="100%"
                                        alt={object.image1x?.altText}
                                    />
                                </div>     
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LimeFlightSection4;